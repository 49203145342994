import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const SEO = ({ description, lang, meta, keywords, title }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `}
    render={({ site }) => {
      const metaDescription = description || site.siteMetadata.description;
      const metaTitle = title || site.siteMetadata.title;
      const social = site.siteMetadata.social;
      return (
        <Helmet
          defaultTitle={site.siteMetadata.title}
          titleTemplate={`%s | ${site.siteMetadata.title}`}>
          <html lang={lang} />
          <title>{title}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
          <meta name="og:type" content="website" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={`@${social.twitter}`} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="twitter:image" content={`${site.siteMetadata.siteUrl}${require("../../images/preview.png").default}`} />
          {keywords.length > 0 && <meta name="keywords" content={keywords.join(', ')} />}
          {meta.map(({ name, content }, i) => (
            <meta key={i} name={name} content={content} />
          ))}
        </Helmet>
      );
    }}
  />
);

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
};

export default SEO;
