import React from 'react';
import { Link } from 'gatsby';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const IconLink = ({ as: LinkComponent, children, href, icon, title, sx }) => {
  const linkProps = LinkComponent === 'a' ? { href, target: '_blank' } : { to: href };

  return (
    <LinkComponent {...linkProps}>
      <Box 
        sx={[{
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center"
        },
        ...(Array.isArray(sx) ? sx : [sx])]}>
        <Box>
          <img alt={title} src={icon} />
        </Box>
        <Box sx={{textAlign: "center"}}>{title}</Box>
      </Box>
    </LinkComponent>
  );
};

IconLink.propTypes = {
  as: PropTypes.oneOf(['a', Link]),
  href: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

IconLink.defaultProps = {
  as: 'a',
  sx: [],
};

export default IconLink;
