import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout } from '../components';
import { FakePlayer } from '../components/elements';

const IndexPage = () => {
  const highlights = useStaticQuery(graphql`
    query HighlightsQuery {
      allHelixVideo(limit: 6, filter: { type: { eq: "highlight" } }) {
        edges {
          node {
            title
            thumbnail_url
            url
          }
        }
      }
    }
  `).allHelixVideo.edges.map(({ node }) => node);

  return (
    <Layout>
      <div>
        <h1>Welcome to Video Games!</h1>
        <p>
          My name is Martin, and I stream video games on Twitch and create Let's Play videos on YouTube. Welcome to my WWW page! 
          The content I create focuses on single player games from the 80s, 90s, and early 2000s - the games I grew up with (or 
          missed out on)! Occasionally I'll play a contemporary game - mostly those made by indie developers and Nintendo.
        </p>

        <h2>Schedule</h2>
        <ul>
          <li>Tuesdays and Thursdays: Bi-Weekly Video Drop (currently playing: "Fahrenheit" / "Indigo Prophecy")</li>
          <li>Sundays: Variety Stream (check Twitch schedule)</li>
        </ul>

        {highlights.length > 0 && (
        <>
          <h2>Highlights</h2>
          <p>Just some highlights snatched from Twitch. Find more by visting my <a href="https://twitch.tv/WelcomeToVideoGames">Twitch page</a>.</p>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              marginTop: "2em",
              gap: "2em"
            }}>
            {highlights.map(({ title, thumbnail_url, url }, i) => (
              <FakePlayer
                key={i}
                title={title}
                thumbnail={thumbnail_url.replace('%{width}', '296').replace('%{height}', '166')}
                href={url} />
            ))}
          </div>
        </>)}
      </div>
    </Layout>
  );
};

export default IndexPage;
