import React from 'react';
import { keyframes } from '@mui/system';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const Marquee = ({ children, duration, sx }) => {
  const scroll = keyframes`
    0% {
      transform: translate3d(0%, 0, 0);
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  `;

  return (
    <Box
      sx={[{
        overflow: "hidden",
        boxSizing: "border-box",
        "> *": {
          display: "inline-block",
          whiteSpace: "nowrap",
          width: "max-content",
          paddingLeft: "100%",
          animation: `${scroll} ${duration}s steps(${duration * 5}, end) infinite`, 
        },
      }, ...Array.isArray(sx) ? sx : [sx]]}>
      <div>{children}</div>
    </Box>
  );
};

Marquee.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  duration: PropTypes.number,
};

Marquee.defaultProps = {
  sx: [],
  duration: 20,
};

export default Marquee;
