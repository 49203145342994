import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const sfn = (str, ext) => {
  const s = str.replace(/[^a-zA-Z0-9]/g, '');
  return `${s.length <= 6 ? s : `${s.substring(0, 6)}~1`}.${ext}`.toUpperCase();
};

const FakePlayer = ({ sx, title, thumbnail, href }) => (
  <a target="_blank" title={sfn(title, 'mp4')} href={href}>
    <Box
      sx={[{
        display: "flex",
        flexDirection: "column",
        img: {
          width: "296px",
          paddingBottom: "49px",
          backgroundImage: `url(${require("../../images/player.png").default})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom left",
        },
      }, ...Array.isArray(sx) ? sx : [sx]]}>
      <img src={thumbnail} alt={title} />
      <Box>{title}</Box>
    </Box>
  </a>
);

FakePlayer.propTypes = {
  thumbnail: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FakePlayer;
