import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { IconLink } from './elements';

const Menu = ({ sx }) => (
  <Box 
    sx={[{
      display: "flex",
      flexDirection: "column", 
      gap: "2em",
      alignItems: "center",
      fontSize: "24px",
      fontFamily: "'Comic Sans Pixelated 24', 'Comic Sans MS', 'Comic Sans', sans-serif"
    },
    ...(Array.isArray(sx) ? sx : [sx])]}>
    <IconLink title="Twitter" icon={require("../images/twitter.gif").default} href="https://twitter.com/WelcomeToVG" />
    <IconLink title="Twitch" icon={require("../images/twitch.gif").default} href="https://twitch.tv/WelcomeToVideoGames" />
    <IconLink title="YouTube" icon={require("../images/youtube.gif").default} href="https://youtube.com/channel/UCOXEX-ulY6tj4Bxe_nOnczw" />
  </Box>
);

Menu.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

Menu.defaultProps = {
  sx: [],
};

export default Menu;
