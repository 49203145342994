import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import RetroHitCounter from 'react-retro-hit-counter';

const Footer = ({ sx }) => (
  <Box 
    sx={[{
      display: "flex",
      flexDirection: "column",
      alignItems: "center", 
      rowGap: "10px",
    },
    ...(Array.isArray(sx) ? sx : [sx])]}>
    <Box>You are visitor number</Box>
    <RetroHitCounter 
      hits={Math.floor(Math.random() * 10000) + 10000}
      size={28}
      withGlow={true}
    />
    <Box sx={{display: "flex", flexDirection: "row"}}>
      <img alt="" src={require("../images/ie.gif").default} />
      <img alt="" src={require("../images/netscape.gif").default} />
    </Box>
    <img src={require("../images/construction.gif").default} alt="Under Construction" />
    <Box>
      <audio loop controls>
        <source src={require("../music/neokobe.m4a").default} type="audio/mp4" />
        <source src={require("../music/neokobe.oga" ).default} type="audio/ogg" />
      </audio>
    </Box>
  </Box>
);

Footer.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

Footer.defaultProps = {
  sx: [],
};

export default Footer;
