import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useInterval } from 'usehooks-ts';
import Menu from './Menu';
import Footer from './Footer';
import { Marquee, SEO } from './elements';

import '../styles/index.scss';
import { ApiClient } from '@twurple/api';
import { ClientCredentialsAuthProvider } from '@twurple/auth';

const client = new ApiClient({ 
  authProvider: new ClientCredentialsAuthProvider(process.env.TWITCH_CLIENT_ID, process.env.TWITCH_CLIENT_SECRET),
});

const Layout = ({ children }) => {
  const [streamTitle, setStreamTitle] = useState(null);

  const fetchAndUpdateStreamTitle = async() => {
    const stream = await client.streams.getStreamByUserId(process.env.TWITCH_USER_ID);
    setStreamTitle(stream !== null ? stream.title : null);
  };

  useEffect(fetchAndUpdateStreamTitle);
  useInterval(fetchAndUpdateStreamTitle, 60000);

  return (
    <>
      <SEO />
      {streamTitle && <Marquee 
        sx={{ 
          position: "absolute", 
          top: 0, 
          left: 0, 
          width: "100%", 
          backgroundColor: "red", 
          color: "white",
          fontFamily: "'Comic Sans Pixelated 16', 'Comic Sans MS', 'Comic Sans', sans-serif",
          lineHeight: "2em"}}>
        LIVE NOW!! "{streamTitle}"
      </Marquee>}
      <Box
        sx={{
          display: "grid",
          '@media (min-width: 1024px)': {
            gridTemplateColumns: "1fr 3fr",
            gridTemplateRows: "auto",
            gridTemplateAreas: `
              "menu main"
              "footer footer"`
          },
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto",
          gridTemplateAreas: `
            "menu"
            "main"
            "footer"`
        }}>
        <Menu 
          sx={{
            gridArea: "menu",
            '@media (max-width: 1024px)': {
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-end"
            },
          }} /> 
        <Box sx={{gridArea: "main", padding: "0 2em"}}>{children}</Box>
        <Footer sx={{gridArea: "footer", paddingTop: "10em"}} />
      </Box>
    </>
  );
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
